import { useState } from 'react';
import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { changePassword } from '@activebrands/core-web/state/auth/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import ThemeButton from 'components/buttons/ThemeButton';
import CloseIcon from 'components/icons/CloseIcon';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 'calc(var(--zindex-header) + 2)',
    width: '420px',

    [media.max['mobile.lg']]: {
        width: '400px',
    },
});

const OverlayContent = styled('div', {
    margin: '0 8px',
    backgroundColor: 'var(--color-bg-secondary)',
    borderRadius: '8px',
    padding: '16px',
});

const OverlayHeader = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
});

const TextWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    margin: '12px 0 24px 0',
});

const ChangePassWordOverlay = ({ setState, newPassword, oldPassword }) => {
    const [css] = useStyletron();
    const dispatch = useTypedDispatch();
    const { user } = useSelector(state => state.auth);
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        setLoading(true);
        setState({ errors: [], isLoading: true });
        const response = await dispatch(
            changePassword({ newPassword, oldPassword, email: user.email, token: user?.token })
        );
        if (response.status === 200) {
            setLoading(false);
            setState({ errors: [], isLoading: false });
        } else {
            setState({ errors: ['invalidOldPassword'], isLoading: false });
            overlay.close('change-password');
        }
    };

    return (
        <Wrapper>
            <OverlayContent>
                <OverlayHeader>
                    <Paragraph fontKeys="Primary/24_94_-1_700">{'Change password'}</Paragraph>
                    <CloseIcon
                        $style={{ cursor: 'pointer' }}
                        width="16px"
                        onClick={() => overlay.close('change-password')}
                    />
                </OverlayHeader>
                <TextWrapper>
                    <span className={css(textStyles['Misc/16_94_400'])}>
                        {`${fm('By clicking continue your password will be updated ')} 
                        ${fm('and you will be logged out. ')} ${fm('Use your new password to log in again. ')}`}
                    </span>
                </TextWrapper>
                <ThemeButton
                    $style={{ marginBottom: '16px', width: '100%', display: 'flex', justifyContent: 'center' }}
                    direction="right"
                    fontKeys="Misc/14_100_-1_500_UC"
                    theme="default"
                    onClick={() => {
                        handleClick();
                    }}
                >
                    {fm('Continue')}
                </ThemeButton>
            </OverlayContent>
        </Wrapper>
    );
};

ChangePassWordOverlay.propTypes = {
    newPassword: PropTypes.string,
    oldPassword: PropTypes.string,
    setState: PropTypes.any,
};

export default ChangePassWordOverlay;
